// Core
import React from "react";
// Components
import { Navbar } from "../components/";
// Styles
import "./styles/root.css";

class Layout extends React.Component {
  render() {
    const {
      children,
    } = this.props;

    return (
      <React.Fragment>
        <Navbar />
        <main role="main">{children}</main>
      </React.Fragment>
    );
  }
}

export default Layout;
