// Core
import React from "react";
import styles from "./styles.module.css";

const Navbar = () => {
  return (
    <header className={styles.root}>
      <img
        alt="InMode"
        src="/images/logo.png"
      />
    </header>
  );
};

export default Navbar;
