// Core
import React from "react";
import styles from "./styles.module.css";

const RegionCard = ({
  image,
  title,
  url,
}) => {
  return (
    <li className={styles.root}>
      <a href={url}>
        <div className={styles.image}>
          <img
            alt={title}
            src={image}
          />
        </div>

        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
      </a>
    </li>
  );
};

export default RegionCard;
